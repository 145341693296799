/* ==============
  Widgets
===================*/

.dashboard-charts {
  height: 300px;
}

.widget-chart li {
  width: 31.5%;
  display: inline-block;
  padding: 0;
  i {
    font-size: 22px;
  }
}

.mini-stat {
  overflow: hidden;
  .mini-stat-desc {
    .verti-label {
      transform: rotate(-90deg);
      position: absolute;
      top: 53px;
      right: -9px;
      letter-spacing: 2px;
    }
  }
  .mini-stat-icon {
    i {
      font-size: 5.5rem;
      position: absolute;
      right: 46px;
      top: -35px;
      color: rgba($white, 0.3);
    }
  }
}

// Recent Activity
.recent-activity-tab {
  .nav-item {
    position: relative;
    padding-top: 30px;
    border-top: 2px solid var(--#{$prefix}border-color);
    &::before {
      content: "";
      position: absolute;
      top: -7px;
      width: 12px;
      height: 12px;
      background: var(--#{$prefix}primary);
      border-radius: 50%;
      border: 2px solid rgba($white, 0.4);
    }
    .nav-link {
      color:var(--#{$prefix}emphasis-color);
      border-radius: 30px;
      position: relative;
      transition: none;

    }
    .nav-link.active {
      color: $white;
      background: var(--#{$prefix}primary) !important;

      &:before {
        content: "";
        position: absolute;
        width: 20px;
        height: 16px;
        background: var(--#{$prefix}primary) !important;
        transform: rotate(-60deg) skew(60deg);
        top: -7px;
      }
    }
  }
}

@media (max-width: 420px) {
  .recent-activity-tab {
    .nav-item {
      .nav-link.active {
        &:before {
          left: 12px;
        }
      }
    }
  }
}

// inbox widget
.inbox-wid {
  .inbox-item {
    position: relative;
    border-bottom: 1px solid var(--#{$prefix}border-color);
    overflow: hidden;
    padding: 10px 0;

    .inbox-item-date {
      font-size: 11px;
      position: absolute;
      right: 7px;
      top: 8px;
    }
  }
}

// Order table
.order-table {
  .badge {
    padding: 0.5em 0.6em;
    text-transform: uppercase;
  }
  .bg-primary {
    background: rgba($primary, 0.5);
  }
  .bg-success {
    background: rgba($success, 0.5);
  }
  .bg-warning {
    background: rgba($warning, 0.5);
  }
}
