//
// morris.scss
//

.morris-charts {
  text {
      font-family: $font-family-secondary !important;
      fill: $gray-500;
  }

}
.morris-hover {
  position: absolute;
  z-index: 10;

  &.morris-default-style {
      font-size: 12px;
      text-align: center;
      border-radius: 8px;
      padding: 10px 12px;
      color: var(--#{$prefix}gray-600);
      border: none;
      font-family: $font-family-base;
      background: var(--#{$prefix}card-bg);
      box-shadow: $box-shadow-lg;


      .morris-hover-row-label {
          font-weight: bold;
          font-family: $font-family-secondary;
          background-color: var(--#{$prefix}gray-300);
          color: var(--#{$prefix}dark);
          padding: 4px;
          border-radius: 5px 5px 0 0;
          margin: -10px -12px 10px;
      }

      .morris-hover-point {
          white-space: nowrap;
          margin: 0.1em 0;
          font-weight: 500;
          font-size: 14px;
          color: var(--#{$prefix}gray-600) !important;
      }
  }
}
