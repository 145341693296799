//
// Range slider
//

.irs--modern {
  .irs-bar,
  .irs-to,
  .irs-from,
  .irs-single {
    background: $primary !important;
    font-size: 11px;
  }
  .irs-to,
  .irs-from,
  .irs-single {
    &:before {
      border-top-color: $primary;
    }
  }

  .irs-line {
    background: var(--#{$prefix}border-color);
    border-color: var(--#{$prefix}border-color);

  }
  .irs-grid-text {
    font-size: 11px;
    color: $gray-400;
  }
  .irs-min,
  .irs-max {
    color: $gray-500;
    border-color: var(--#{$prefix}border-color);
    font-size: 11px;
    background-color: var(--#{$prefix}border-color) !important;

  }

  .irs-handle {
    width: 12px;
    height: 12px;
    top: 37px;
    background-color: $card-bg !important;

    > i {
      &:nth-child(1) {
        width: 8px;
        height: 8px;
      }
    }
  }
}
