//
// Extras pages.scss
//

// directory

.directory-card {
  .directory-card-bg {
    background: url("../images/bg-directory-pattern.png");
    background-size: cover;
    background-position: center;
  }
  .social-icons {
    .social-links {
      position: absolute;
      bottom: 20px;
      right: 0px;
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
      background: rgba($primary, 0.5);
    }
  }
}

//Error Page
.ex-page-content {
  h1 {
    font-size: 98px;
    font-weight: 500;
    line-height: 150px;
    text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;
  }
}


//Pricing Page
.pricing-card {
  transition: all 0.5s ease-in;
  &:hover,
  &:active,
  &.active {
    background-color: rgba($primary, 0.3);
    .form-check-input {
      background-color: var(--#{$prefix}primary);
      position: relative;
      &::before{
        position: absolute;
        content: '\f012c';
        font-family: "Material Design Icons";
        top: 0;
        left: 3px;
        font-size: 16px;
        color: $white;
      }
    }
  }
}
.pricing-checkbox {
  width: 25px;
  height: 25px;
  border-radius: 50% !important;
}

[data-theme-mode="red"]{
  .pricing-nav{
    background-color: rgba($danger, 0.12) !important;
   .nav-item{
    .nav-link{
      color: $danger;
      &.active{
        color: $white;
      }
    }
   }
  }
  .pricing-card{
    &:hover,
    &:active,
    &.active{
    background-color: rgba($danger, 0.3);
    }
  }
  .bg-soft-danger{
    background-color: rgba($info, 0.12) !important;
  }
}

[data-theme-mode="green"]{
  .pricing-nav{
    background-color: rgba($success, 0.12) !important;
   .nav-item{
    .nav-link{
      color: $success;
      &.active{
        color: $white;
      }
    }
   }
  }
  .pricing-card{
    &:hover,
    &:active,
    &.active{
    background-color: rgba($success, 0.3);
    }
  }
}