/* ==============
  Timeline
===================*/
/* Activity */
.activity-feed {
  padding: 15px 15px 0 15px;
  list-style: none;

  .feed-item {
    position: relative;
    padding-bottom: 29px;
    padding-left: 30px;
    border-left: 2px solid $light;

    &:last-child {
      border-color: transparent;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -10px;
      width: 16px;
      height: 10px;
      border-radius: 6px;
      background: $white;
      border: 4px solid $primary;
    }

    .feed-item-list {
      padding: 12px 20px;
      border-radius: 7px;
      background: $gray-100;
      &::after {
        left: 19px;
        top: 0;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-top-color: $gray-100;
        border-width: 10px;
        margin-left: -1px;
        border-right-color: $gray-100;
      }
    }

    .date {
      display: block;
      position: relative;
      top: -5px;
      color: #8c96a3;
      text-transform: uppercase;
      font-size: 13px;
    }
    .activity-text {
      position: relative;
      top: -3px;
    }
  }
}

/* Timeline */
.cd-container {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}
.cd-container::after {
  content: "";
  display: table;
  clear: both;
}
#cd-timeline {
  margin-bottom: 2em;
  margin-top: 2em;
  padding: 2em 0;
  position: relative;
  &::before {
    border-left: 3px solid rgba($light, 0.7);
    content: "";
    height: 100%;
    left: 18px;
    position: absolute;
    top: 0;
    width: 3px;
  }
}
@media only screen and (min-width: 1170px) {
  #cd-timeline {
    margin-bottom: 3em;
    margin-top: 3em;
    &::before {
      left: 50%;
      margin-left: -2px;
    }
  }
}

.cd-timeline-block {
  margin: 2em 0;
  position: relative;
  &:after {
    clear: both;
    content: "";
    display: table;
  }
}
.cd-timeline-block:first-child {
  margin-top: 0;
}
.cd-timeline-block:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-block {
    margin: 4em 0;
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
}
.cd-timeline-img {
  position: absolute;
  top: 29px;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 34px;
  font-size: 20px;
  color: $white;
  background-color: $primary;
  border: 3px solid $white;
  z-index: 1;
  box-shadow: $box-shadow;

  i {
    margin-left: 1px;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-img {
    width: 40px;
    height: 40px;
    line-height: 34px;
    left: 50%;
    margin-left: -20px;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  .cd-timeline-content {
    .cd-date.date-right {
      &:before {
        content: "";
        top: 0px;
        left: 100%;
        border-color: transparent;
        border-left-color: rgba($light, 0.9);
      }
    }
  }
}

.cd-timeline-content {
  border-radius: 5px;
  margin-left: 60px;
  padding: 1em;
  position: relative;
  box-shadow: $box-shadow;

  &:after {
    clear: both;
    content: "";
    display: table;
  }
  h2 {
    margin-top: 0;
  }
  .cd-read-more {
    background: $primary;
    border-radius: 0.25em;
    color: $white;
    display: inline-block;
    float: right;
    font-size: 14px;
    padding: 0.8em 1em;
  }
  .cd-date {
    display: inline-block;
    font-size: 14px;
  }
  h3 {
    font-size: 18px;
    margin: 6px 0 18px 0;
  }
}

.cd-timeline-content {
  .cd-date {
    float: left;
    padding: 4px 22px;
    opacity: 0.7;
    background: rgba($light, 0.9);
    &:before {
      content: "";
      position: absolute;
      top: 1px;
      right: 100%;
      height: 0;
      width: 0;
      border: 14px solid transparent;
      border-right: 14px solid rgba($light, 0.9);
    }
  }
}

@media (max-width: 1170px) {
  .cd-timeline-content {
    .cd-date {
      &:before {
        top: 34px;
      }
    }
  }
}

@media only screen and (min-width: 1170px) {
  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 42%;
  }
  .cd-timeline-content.right-content {
    text-align: right;
  }

  .cd-timeline-content .cd-read-more {
    float: left;
  }
  .cd-timeline-content .cd-date {
    position: absolute;
    width: 50%;
    left: 84%;
    top: 17px;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 84%;
    text-align: right;
  }
}
