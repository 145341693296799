.form-check {
  position: relative;
}

//check-boxes

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: $form-check-padding-start;
  .form-check-input {
    float: right /*rtl:left*/;
    margin-left: 0;
    margin-right: $form-check-padding-start * -1;
  }
  .form-check-label {
    display: block;
  }
}

.form-check {
  position: relative;
  text-align: left /*rtl:right*/;
}

[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  &::placeholder {
    text-align: left /*rtl: right*/;
  }
}

//form-editable
a.editable-click{
  color: var(--#{$prefix}primary);
}

a.editable-empty{
  color: #DD1144;
}

.form-control{
  background-color: var(--#{$prefix}input-bg);
  border: 1px solid $gray-400;
}

body[data-bs-theme="dark"]{
  .form-control{
    background-color: #393f46;
    border: 1px solid #474e55;
  }
}