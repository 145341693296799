//  default
[data-theme-mode="default"] {
    --bs-danger: #f16c69 !important;
    --bs-danger-rgb: 241, 108, 105 !important;
    
    --bs-success: #35a989 !important;
    --bs-success-rgb: 53, 169, 137 !important;

    --bs-primary: #1b82ec !important;
    --bs-primary-rgb: 27, 130, 236 !important;
}

// Red
[data-theme-mode="red"] {
    --bs-primary: #f16c69 !important;
    --bs-primary-rgb: 241, 108, 105 !important;
    
    --bs-warning: #29bbe3 !important;
    --bs-warning-rgb: 41, 187, 227 !important;

    --bs-success:  #35a989 !important;
    --bs-success-rgb: 53, 169, 137 !important;

    --bs-info: #f5b225 !important;
    --bs-info-rgb: 245, 178, 37 !important;
}

// Green
[data-theme-mode="green"] {
    --bs-primary: #35a989 !important;
    --bs-primary-rgb: 53, 169, 137 !important;
    
    --bs-danger: #f16c69 !important;
    --bs-danger-rgb: 241, 108, 105 !important;

    --bs-success:  #1b82ec !important;
    --bs-success-rgb: 27, 130, 236 !important;
}